@font-face {
    font-family: 'Raleway';
    font-style:  normal;
    font-weight: 700;
    src: url("Raleway-Bold.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Raleway';
    font-style:  normal;
    font-weight: 600;
    src: url("Raleway-SemiBold.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Raleway';
    font-style:  normal;
    font-weight: 400;
    src: url("Raleway-Regular.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-style:  normal;
    font-weight: 500;
    src: url("Poppins-Medium.ttf") format("truetype");
    font-display: swap;
}
